.creative-list-ctn{
    padding: 0% 7%;
    padding-bottom:2%;
   
}
.cubeFunctionality{
    .cubeIcon{
        width: 100%;
        display: flex;
        justify-content: center;
        top: -21px;
        position: relative;
    }
}