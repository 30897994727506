@import '../global';

.faqList .faq {
	box-shadow: none;
	width: 100%;
	margin: 0;

	&::before {
		background-color: rgba(11, 77, 204, 0.15);
	}
	.faqQuestion {
		color: #636363;
		font-family: 'Poppins Regular';
    font-size: 0.8rem;
	}
	.faqAnswer {
		font-family: 'Poppins Light';
		color: #636363;
		font-size: 0.7rem;
		// text-align: justify;

		ol,
		ul {
			list-style-position: inside;
		}
	}
	.MuiAccordionSummary-content {
		margin: 12px 0;
		display: flex;
		flex-grow: 1;
		transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
		margin-left: 22px;
	}
	.MuiAccordionSummary-root.Mui-expanded {
		min-height: 0px;
		height:30px;
	}
	.MuiIconButton-edgeEnd {
		/* margin-right: -12px; */
		float: left;
		left: 0px;
		margin-left: 0px;
		position: absolute;
	}
	.MuiAccordionSummary-root {
		display: flex;
		padding: 0px 20px;
		min-height: 30px;
		height: 41px;
		transition: min-height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	}
	.MuiIconButton-root {
		flex: 0 0 auto;
		color: rgba(0, 0, 0, 0.54);
		padding: 0px; 
		overflow: visible;
		font-size: 1.5rem;
		text-align: center;
		transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
		border-radius: 50%;
	}
	.MuiAccordionDetails-root {
		padding:0px 16px 0px;
	}
}
.faq.Mui-expanded {
	box-shadow: none;
}

