@import './global';

.footer {
	@include flexRow;
	@include flexVAlign;
	background: linear-gradient(90deg, #f84c69, #f79759, #ebe478, #a0fb77, #64ed97, #57b2f3, #e475f3);
	height: 80px;
	width: 100%;
	color: #fff;
	font-family: 'Poppins Regular';
	font-size: 0.9rem;
	position: relative;
	@include sm {
		height: 70px;
		font-size: 0.8rem;
	}
	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: url('../assets/images/banner_grid.png');
		background-size: 3.6rem;
		opacity: 0.25;
	}

	.footerContent {
		@include flexRow;
		@include flexSpaceBetween;
		padding: 0 7%;
		width: 100%;
		@include sm {
			padding: 0 5%;
		}
		@include xs {
			@include flexCenter;
			flex-direction: column-reverse;
		}

		.mailDetail {
			@include flexRow;
			@include flexEnd;
			@include xs {
				margin: 0 0 10px;
			}

			.mailBtn {
				padding: 0;

				.mailIcon {
					color: rgba($color: #fff, $alpha: 0.9);
					font-size: 1.7rem;
					@include xs {
						font-size: 1.4rem;
					}
				}
			}
		}
	}
}
