@import '../global';

.creativeAuthor {
	color: $text-color;
	
	margin: 4rem 0 0;
	color: #636363;
    font-family: 'Poppins Light';
    font-size: .7rem;
    margin: 1rem 0 0 1.3rem;
	@include sm {
		font-size: 0.8rem;
		margin: 3rem 0 0;
	}
	@include xs {
		margin: 2rem 0 0;
	}
}
