@import './../global';

$screen-xs-max: 320px;
$screen-sm-max: 480px;
// Media Query Mixins
@mixin xs {
	@media (max-width: #{$screen-xs-max}) {
		@content;
	}
}
@mixin sm {
	@media (max-width: #{$screen-sm-max}) {
		@content;
	}
}

.vhomeBanner {
	@include flexRow;
	@include flexCenter;
	// display: block;
	margin-top: 64px;
	background: linear-gradient(
		90deg,
		#f84c69,
		#f79759,
		#ebe478,
		#a0fb77,
		#64ed97,
		#57b2f3,
		#e475f3,
		#f84c69,
		#f79759,
		#ebe478,
		#a0fb77,
		#64ed97,
		#57b2f3,
		#e475f3,
		#f84c69
	);
	background-size: 400% 100%;
	animation: gradient 120s linear infinite forwards;
	color: #fff;
	height: 145px;
	position: relative;
	@include lg {
		height: 145px;
	}
	@include md {
		height: 145px;
	}
	@include sm {
		height: 145px;
	}
	@include xs {
		// width: 320px;
		height: 145px;
	}
	@keyframes gradient {
		0% {
			background-position: 0% 0%;
		}
		100% {
			background-position: 400% 0%;
		}
	}
	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: url('../../assets/images/banner_grid.png');
		background-size: 3.6rem;
		opacity: 0.25;
	}

	.content {
		// @include flexColumn;
		// @include flexCenter;
		height: 100%;
		width: 100%;
		max-width: 1800px;
		margin-top: 50px;
		// margin: 0 50px;
		padding: 0;
		@include lg {
			margin: 0;
			// padding: 0 7%;
		}

		.heading {
			font-family: 'Poppins Bold';
			text-align: center;
			text-transform: uppercase;
			font-size: 1.8rem;
			letter-spacing: 0.1rem;
			margin-top: 27px;
			@include lg {
				font-size: 1.3rem;
			}
			@include md {
				font-size: 1.3rem;
			}
			@include sm {
				font-size: 1rem;
			}
			@include xs {
				font-size: 0.8rem;
			}
		}

		.labIcons {
			display: block;
			width: auto;
			height: 100%;
			margin: 0 auto;
			// @include xs {
			// 	position: absolute;
			// 	left: 0;
			// }
		}
	}
}
