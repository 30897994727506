@import './global';

.searchBarCtn {
	position: relative;
	width: 50%;
	z-index: 10;
	@include xl {
		width: 60%;
	}
	@include lg {
		width: 70%;
	}
	@include md {
		width: 80%;
	}
	@include sm {
		width: 90%;
	}
	@include xs {
		width: 100%;
	}

	.searchBar {
		padding: 0 12px;
		background: #fff;
		border-radius: 50px;

		.searchInputWrapper {
			@include flexRow;
			@include flexVAlign;
			position: relative;

			.searchIcon {
				color: $text-color;
				transform: rotateY(180deg);
				font-size: 20px;
				margin: 0 20px 0 10px;
				@include sm {
					margin: 0 10px;
				}
			}
			.searchInput {
				font-size: 0.95rem;
				font-family: 'Poppins Regular';
				width: 90%;
				border: none;
				border-radius: 0 50px 50px 0;
				padding: 20px 0;
				@include lg {
					font-size: 0.9rem;
				}
				@include md {
					font-size: 0.85rem;
					padding: 18px 0;
				}
				@include sm {
					font-size: 0.75rem;
					padding: 14px 0;
					width: 85%;
				}
			}
			.searchInputFocus {
				border-bottom: 1px solid rgba(0, 0, 0, 0.3);
				border-radius: 0 50px 0 0;
			}
			.searchInfoText {
				position: absolute;
				right: 0;
				font-family: 'Poppins Regular';
				font-size: 0.7rem;
				color: $text-color;
			}
		}
	}
	.searchBarFocus {
		border-radius: 25px 25px 0 0;
		border-width: 3px 3px 0 3px;
	}
	.searchScrollerContainer{
		
			
			width: 100%;
			
			max-height: 400px;
			overflow-y: scroll;
			position: absolute;
			min-height: 300px;
			border-radius: 0 0 25px 25px;
		
	}
	.searchResultWrapper {
		border-width: 0px 3px 3px 3px;
		border-radius: 0 0 25px 25px;
		position: absolute;
		background: #fff;
		width: 100%;
		padding: 0 12px;
		font-size: 0.9rem;
		list-style: none;

		a:last-child {
			.searchResult {
				border-bottom: none;
			}
		}
		.searchResult {
			padding: 10px;
			border-bottom: 1px solid rgba(0, 0, 0, 0.1);
			&:hover {
				background-color: #f2f2f2;
			}

			.searchResultTitle {
				font-family: 'Poppins Bold';
				color: $text-color;
				text-transform: capitalize;
				display: flex;
			}
			.searchResultDescription {
				font-family: 'Poppins Regular';
				font-size: 0.7rem;
				padding-top: 5px;
				color: $text-color;
			}
			.lockContainer{
				border-radius: 15px;
				border: 1px solid #d85c58;
				width: 9%;
				position: absolute;
				right: 3%;
				color: #d85c58;
				font-family: 'Poppins Regular';
				text-align: center;
				.mockup{
					margin: 0px 9px;
					// color: red;
					font-size: 0.7rem;
				}
			}
			
			
		}
		.mockupResult {
			background: #d85c581a;
		}
		.loader {
			padding: 15px 0;
			text-align: center;
		}
	}
}
