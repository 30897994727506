@import '../global';

$tagMargin: 5px;

.creativeTags {
	@include flexRow;
	position: relative;
	margin-top: 64px;
	background: linear-gradient(
		90deg,
		#f84c69,
		#f79759,
		#ebe478,
		#a0fb77,
		#64ed97,
		#57b2f3,
		#e475f3,
		#f84c69,
		#f79759,
		#ebe478,
		#a0fb77,
		#64ed97,
		#57b2f3,
		#e475f3,
		#f84c69
	);
	background-size: 400% 100%;
	animation: gradient 120s linear infinite forwards;
	padding: calc(2rem - #{$tagMargin}) calc(7% - #{$tagMargin});
	@include md {
		padding: calc(1.5rem - #{$tagMargin}) calc(7% - #{$tagMargin});
	}
	@include sm {
		padding: calc(1.2rem - #{$tagMargin}) calc(5% - #{$tagMargin});
	}
	@keyframes gradient {
		0% {
			background-position: 0% 0%;
		}
		100% {
			background-position: 400% 0%;
		}
	}
	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: url('../../assets/images/banner_grid.png');
		background-size: 3.6rem;
		opacity: 0.25;
	}

	.backBtn {
		color: #fff;
		margin-right: 2rem;
		@include sm {
			display: none;
		}
	}
	.creativeTagsContent {
		@include flexRow;
		@include flexVAlign;
		flex-wrap: wrap;
		z-index: 1;

		.creativeTag {
			@include flexRow;
			@include flexCenter;
			background-color: #fff;
			color: $text-color;
			padding: 6px 12px;
			border-radius: 40px;
			font-family: 'Poppins Regular';
			font-size: 0.85rem;
			text-transform: capitalize;
			text-align: center;
			margin: #{$tagMargin};
			@include md {
				font-size: 0.75rem;
			}

			&:hover {
				background-color: $text-color;
				color: #fff;
			}
		}
	}
}
