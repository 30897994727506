@import '../global';

.faqList {
	margin-top: 0px;
	padding: 3% 0%;
	padding-top:0%;
	@include md {
		padding: 0%;
	}
	@include sm {
		padding: 0% 5%;
		padding-left: 0;
	}
	@include xs {
		padding: 0% 5%;
		padding-left: 0;
	}

	.faqHeading {
		color: $primary-color;
		font-family: 'Poppins Medium';
		font-size: 3rem;
		margin: 0 0 40px 0;
		@include md {
			font-size: 2.5rem;
			margin: 0 0 30px 0;
		}
		@include sm {
			font-size: 2.2rem;
		}
		@include xs {
			margin: 0 0 25px 0;
		}
	}
	.container{
		background: transparent;
	}
	.faqList .faq .MuiAccordionSummary-root {
		
		@include sm {
			height: 41px;
		}
		@include xs {
			height: 41px;
		}
	}
}
