@import '../global';

.exampleContainer {
    /* display: inline-flex; */
    width: 100%;
    border: 0px solid red;
    position: relative;

    .phoneBg {
        width: 100%;
    }
    .cardContent {
        @include flexColumn;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        transform: translateY(100%);
        color: #fff;
        // padding: 20px;
        align-items: center;
        // height: 100%;
        // justify-content: space-between;
        z-index: 9999;
        @include md {
            @include hidden;
        }

        .bulbIcon {
            width: 50px;
        }
        .cardViewBtn {
            padding: 10px;
            width: 50%;
            background: rgba(255, 255, 255, 0.2);
            border: 1px solid #fff;
            color: #fff;
            text-transform: uppercase;
            margin: 0 auto;
            text-align: center;
        }
        .cardTitle {
            font-family: 'Poppins SemiBold';
            font-size: 1.3rem;
            line-height: 1.2;
            margin-top: 60%;
            text-align: center
        }
        .cardRegionDate {
            @include flexRow;
            @include flexSpaceBetween;
            font-family: 'Poppins Regular';
            font-size: 0.95rem;
        }
    }
    .exampleCardContentSection {
        position: absolute;
        top: 10%;
        align-items: center;
        left: 6%;
        /* border: 1px solid green; */
        z-index: 3;
        width: 88%;
        &::after {
            content: '';
            background-image: conic-gradient(
                from 180deg,
                #f84c69,
                #f79759,
                #ebe478,
                #a0fb77,
                #64ed97,
                #57b2f3,
                #e475f3,
                #f84c69
            );
            opacity: 0;
            position: absolute;
            bottom: 0;
            left: 0;
            height: 100%;
            width: 100%;
            @include md {
                @include hidden;
            }
        }
    }
    .exampleCardContentSection img{
        width: 100%;
    }
    .cardViewBtn {
        padding: 10px;
        width: 50%;
        background: rgba(255, 255, 255, 0.2);
        border: 1px solid #fff;
        color: #fff;
        text-transform: uppercase;
        margin: 0 auto;
        text-align: center;
    }
    .cardTitle {
        font-family: 'Poppins SemiBold';
        font-size: 1.3rem;
        line-height: 1.2;
        margin-top: 60%;
    }
    .cardRegionDate {
        @include flexRow;
        @include flexSpaceBetween;
        font-family: 'Poppins Regular';
        font-size: 0.95rem;
    }
    &:hover {
		//box-shadow: 0 4px 10px 3px rgba(0, 0, 0, 0.2) !important;
		transition: all 0.3s;

		
				.exampleCardContentSection::after {
					transition: all 0.3s;
					opacity: 0.8;
				}
				.cardContent {
					transition: all 0.3s;
					transform: translateY(0);
				}
			
		
	}
}