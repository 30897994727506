@import './global';

.errorMsg {
	margin-top: 64px;
	padding: 5% 7%;
	text-align: center;
	@include md {
		padding: 7%;
	}
	@include sm {
		padding: 10% 5%;
	}
}


.pageNotFound {
	@include flexRow;
	@include flexCenter;
	margin-top: 64px;
	padding: 4% 7%;
}
