@import '../global';

.creativeList {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
	grid-gap: 2rem;
	@include xs {
		grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
		grid-gap: 1rem;
	}
}
.creativesLoader {
	@include flexRow;
	@include flexCenter;
	margin: 2rem 0 0 0;
	width: 100%;
	@include xs {
		margin: 1rem 0 0 0;
	}
}
.emptyCreativeList {
	text-align: center;
    font-family: 'Poppins Regular';
    color: #767474;
	font-size: 0.85rem;
	line-height: 1.6rem;;
}
.creativeCount{
	margin-bottom: 10px;
    font-family: 'Poppins Regular';
    color: #636363;
    font-size: 0.8rem;
}
