@import '../global';

.authorize {
	@include flexColumn;
	@include flexVAlign;
	height: 100%;
	padding: 10% 7% !important;
	background: white;
	@include md {
		padding: 15% 7%;
	}
	@include sm {
		padding: 20% 7%;
	}
	@include xs {
		padding: 25% 7%;
	}
	.lockImageContainer {
		width: 5%;
		max-width: 10rem;
		margin-bottom: 5%;
		@include sm {
			width: 15%;
		}
		@include xs {
			width: 15%;
		}
	}
	.lockIcon {
		width: 100%;
	}
	h4 {
		font-family: 'Poppins Medium';
		margin: 35px 0 10px;
		text-align: center;
		@include sm {
			font-size: 0.9rem;
		}
	}
	span {
		font-family: 'Poppins Regular';
		color: rgba(0, 0, 0, 0.54);
		text-align: center;
		@include sm {
			font-size: 0.85rem;
		}
	}
	.button {
		font-family: 'Poppins Medium';
		margin-top: 15px;
		background-color: #ea5252 !important;
		color: #ffffff;
		border-radius: 50px;
		text-transform: none;
		padding: 6px 25px;
		span {
			color: white;
		}
	}
}
