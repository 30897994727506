@import '../components/global';

.loginRedirect {
	@include flexRow;
	justify-content: center;
	text-align: center;
	font-family: 'Poppins Regular';
	padding: 10% 7%;
	@include md {
		padding: 15% 7%;
	}
	@include sm {
		padding: 20% 7%;
	}
	@include xs {
		padding: 25% 7%;
	}
}
