@import './global';

.appDrawerContents {
	width: 70vw;

	.appDrawerLogo {
		@include flexRow;
		@include flexVAlign;

		.logoCtn {
			@include flexColumn;
			@include flexCenter;
			max-width: 15rem;
			width: 90%;

			img {
				width: 100%;
			}
			.logoSubText {
				color: $text-color;
				font-family: 'Poppins Light';
				font-size: 0.5rem;
				margin: 2px 0 0;
				text-align: center;
			}
		}
	}

	.menuItem {
		color: $text-color;

		.menuItemIcon {
			min-width: 45px;
		}
		.menuItemText span {
			font-family: 'Poppins Regular';
			font-size: 0.9rem;
		}
	}
	.listContainer{
		
		.menuItemIcon {
			min-width: 45px;
		}
		.menuItemText span{
			color: $text-color;
			font-family: 'Poppins Regular';
			font-size: 0.9rem;
		}
	}
}
