

.exampleContainer {
    /* display: inline-flex; */
    width: 100%;
    border: 0px solid red;
    position: relative;
}
.phoneBg {
    width: 100%;
}
.exampleCardContentSection {
    position: absolute;
    top: 10%;
    align-items: center;
    left: 6%;
    /* border: 1px solid green; */
    z-index: 3;
    width: 88%;
}
.exampleCardContentSection img{
    width: 100%;
}