@import '../global';

.creativeCard {
	box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.2) !important;
	position: relative;
	transition: all 0.3s;
	border-bottom: 6px solid;
	border-top: 0;
	border-left: 0;
	border-right: 0;
	border-image-slice: 1;
	border-image-source: linear-gradient(
		to right,
		#f84c69,
		#f79759,
		#ebe478,
		#a0fb77,
		#64ed97,
		#57b2f3,
		#e475f3
	);

	.bulbIcon {
		width: 80%;
		display: flex;
		position: absolute;
		z-index: 1;
		justify-content: flex-start;
		background-image: url(./../../assets/images/lockoverlay.png);
		height: 80%;
		background-repeat: no-repeat;
		background-size: 100% auto;
		.bulbImg {
			width: 100%;
			
		}
	}
	.creativeCardWrapper {
		position: relative;
		box-shadow: none;
		border-radius: 0;
		height: 100%;

		.creativeCardActionArea {
			height: 100%;
			padding-top: 177.78%;

			.creativeImageCtn {
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;

				.creativeImage {
					height: auto;
					width:100%;
				}

				&::after {
					content: '';
					background-image: conic-gradient(
						from 180deg,
						#f84c69,
						#f79759,
						#ebe478,
						#a0fb77,
						#64ed97,
						#57b2f3,
						#e475f3,
						#f84c69
					);
					opacity: 0;
					position: absolute;
					bottom: 0;
					left: 0;
					height: 100%;
					width: 100%;
					@include md {
						@include hidden;
					}
				}
			}
			.cardContent {
				@include flexColumn;
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				transform: translateY(100%);
				color: #fff;
				align-items: center;
				// padding: 20px;
				// height: 100%;
				// justify-content: space-between;
				@include md {
					@include hidden;
				}

				.bulbIcon {
					width: 50px;
				}
				.cardViewBtn {
					padding: 10px;
					width: 50%;
					background: rgba(255, 255, 255, 0.2);
					border: 1px solid #fff;
					color: #fff;
					text-transform: uppercase;
					margin: 0 auto;
					text-align: center;
				}
				.cardTitle {
					font-family: 'Poppins SemiBold';
					font-size: 1.3rem;
					line-height: 1.2;
					margin-top: 60%;
					text-align: center
				}
				.cardRegionDate {
					@include flexRow;
					@include flexSpaceBetween;
					font-family: 'Poppins Regular';
					font-size: 0.95rem;
				}
			}
		}
	}

	&:hover {
		box-shadow: 0 4px 10px 3px rgba(0, 0, 0, 0.2) !important;
		transition: all 0.3s;

		.creativeCardWrapper {
			.creativeCardActionArea {
				.creativeImageCtn::after {
					transition: all 0.3s;
					opacity: 0.8;
				}
				.cardContent {
					transition: all 0.3s;
					transform: translateY(0);
				}
			}
		}
		.bulbIcon{
			background-image: url(./../../assets/images/lockicon.png);
		}
	}
	.skelImg{
		height: 200px;;
	}
	.imageLoaderContainer{
		display: flex;
    position: absolute;
    top: 0;
    text-align: center;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    align-content: center;
    width: 100%;
    height: 100%;
	}
	.imageLoaderContainer img{
		width: 50%;
	}
}
