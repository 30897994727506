@import '../global';

.filterSection {
	@include flexRow;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	// margin-bottom: 50px;

	// @include md {
	// 	margin-bottom: 30px;
  // }
  .demoFilterType{
   
    background: #fafafa;
    padding: 6px 11px;
    border-radius: 32px;
    font-size: 0.85rem;
    color: #767474;
    border: 1px solid #ccc;
    font-family: 'Poppins Regular';
    margin-right: 1.2%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
}
.filterSection1{
  display: flex;
    width: 100%;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    .demoFilterType {
      /* background: #fafafa; */
      padding: 6px 11px;
      border-radius: 32px;
      font-size: 0.85rem;
      color: #767474;
      /* border: 1
  px
   solid #ccc; */
      font-family: 'Poppins Regular';
      margin: 0 5%;
      margin-left: 0;
      width: 100%;
  }
}
.toggleSwitch span span {
	display: none;
  }
.toggleSwitch {
	display: flex;
    height: 40px;
    position: relative;
    overflow: visible;
    padding: 0;
    /* margin-left: 50px; */
    cursor: pointer;
    width: 100%;
  }
  .toggleSwitch * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .toggleSwitch label,
  .toggleSwitch > span {
    line-height: 20px;
    height: 20px;
    vertical-align: middle;
  }
  .toggleSwitch input:focus ~ a,
  .toggleSwitch input:focus + label {
    outline: none;
  }
  .toggleSwitch label {
    position: relative;
    z-index: 3;
    display: block;
    width: 100%;
  }
  .toggleSwitch input {
    position: absolute;
    opacity: 0;
    z-index: 5;
  }
  .toggleSwitch > span {
    position: absolute;
    left: 0px;
    width: 100%;
    margin: 0;
    padding-right: 9px;
    text-align: left;
    white-space: nowrap;
    color: #636363;
    font-family: 'Poppins Regular';
    font-size: 0.8rem;
  }
  .toggleSwitch > span span {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    display: block;
    width: 50%;
    margin-left: 50px;
    text-align: left;
    font-size: 1rem;
    width: 100%;
    // left: 15%;
    top: 10px;
	opacity: 0;
	color: #636363;
  
    font-family: 'Poppins Regular';
    font-size: 0.8rem;
  }
  .toggleSwitch a {
    position: absolute;
    // right: 50%;
    z-index: 4;
    display: block;
    height: 35px;
    padding: 0;
    // left: 0.2%;
    width: 35px;
    top: 1px;
    background-color: #fff;
    border: 1px solid #CCC;
    border-radius: 100%;
    transition: all 0.2s ease-out;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }
  .toggleSwitch > span span:first-of-type {
    // color: #282727;
    opacity: 1;
    // left: 45%;
  }
  .toggleSwitch > span:before {
    content: "";
    display: block;
    width: 100%;
    height: 35px;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 30px;
    -webkit-transition: all .2s;
    transition: all .2s;
  }
  .toggleSwitch input:checked ~ a {
    border-color: #fff;
	// left: 85%;
	right:-2px;
	margin-left: 0px;
	border: 1px solid #CCC;
  }
  .toggleSwitch input:checked ~ span:before {
    border-color: #bdb4b4;
    box-shadow: inset 0 0 0 30px #fafafa;
  }
  .toggleSwitch input:checked ~ span span:first-of-type {
    opacity: 0;
  }
  .toggleSwitch input:checked ~ span span:last-of-type {
    opacity: 1;
    color: #000;
  }
  /* Switch Sizes */
  .toggleSwitch.large {
    width: 60px;
    height: 27px;
  }
  .toggleSwitch.large a {
    width: 27px;
  }
  .toggleSwitch.large > span {
    height: 29px;
    line-height: 28px;
  }
  .toggleSwitch.large input:checked ~ a {
    left: 41px;
  }
  .toggleSwitch.large > span span {
    font-size: 1.1em;
  }
  .toggleSwitch.large > span span:first-of-type {
    // left: 50%;
  }
  .toggleSwitch.xlarge {
    width: 80px;
    height: 36px;
  }
  .toggleSwitch.xlarge a {
    width: 36px;
  }
  .toggleSwitch.xlarge > span {
    height: 38px;
    line-height: 37px;
  }
  .toggleSwitch.xlarge input:checked ~ a {
    left: 52px;
  }
  .toggleSwitch.xlarge > span span {
    font-size: 1.4em;
  }
  .toggleSwitch.xlarge > span span:first-of-type {
    left: 50%;
  }

