@import './../global';
.functionalityCardContainer {
	.borderBottom {
		transition: all 0.3s;
		border-bottom: 6px solid;
		border-image-slice: 1;
		border-image-source: linear-gradient(
			to right,
			#f84c69,
			#f79759,
			#ebe478,
			#a0fb77,
			#64ed97,
			#57b2f3,
			#e475f3
		);
		@include sm {
			border-bottom: 3px solid;
		}
	}
	.creative-card {
		box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.1) !important;
		height: 100%;
		border: 1px solid #a29c9c8c;
	}
	.boxContainer {
		height: 210px;
	}
	// .creative-card {
	//     box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.1) !important;
	//     height: 100%;
	// }
	.cardContainer {
		padding: 5px;
		text-align: center;
	}
	.creative-card:hover {
		box-shadow: 0 4px 10px 3px #9e9e9e4f !important;
	}
	.creative-card:hover .card-cta {
		color: #ea5252;
		background: #fff;
	}
	// .creative-card:hover .card-icon .outerbox{
	//      background: url('../../assets/images/hexboxover.png');
	//     background-size: auto 100%;
	//     background-repeat: no-repeat;
	// }
	.creative-card:hover .verticalIcon {
		background: url('../../assets/images/innerHexboxover.png');
		background-size: 100% auto;
		background-repeat: no-repeat;
	}
	.creative-card .verticalIcon {
		background: url('../../assets/images/innerHexbox.png');
		background-size: 100% auto;
		background-repeat: no-repeat;
		//    background:red;
		// filter:url('../../assets/images/innerHexbox.png') opacity(1);
	}
	// .creative-card:hover .card-icon {
	//     background: #ebebeb;
	// }
	.creative-card-content {
		height: 100%;
		justify-content: space-between;
	}
	.card-icon {
		// background: url('../../assets/images/hexbox.png');
		width: 130px;
		/* border-radius: 100%; */
		background-size: auto 100%;
		background-repeat: no-repeat;
		text-align: center;
		margin: 0 auto;
		/* background-size: cover; */
		height: 144px;
	}
	.functionalityIcon {
		width: 60%;
		/* border: 1px solid blue; */
		/* height: 100%; */
		display: flex;
		text-align: center;
		justify-content: center;
		align-content: center;
	}
	.card-icon img {
		width: 100%;
		filter: grayscale(1) opacity(0.8);
	}
	.hexContainer {
		width: 90%;
		height: auto;
		background-size: 100% auto;
		position: absolute;
		margin: 10% 0%;
	}
	.verticalIcon {
		width: 100%;
		/* border: 1px solid blue; */
		height: 100%;
		display: flex;
		text-align: center;
		justify-content: center;
		align-content: center;
		position: relative;
		margin: 3% 3%;
	}
	.verticalIcon img {
		width: 100%;
	}
	.card-title {
		// color: #0a4dcc;
		text-transform: uppercase;
		line-height: 1;
		width: 100%;
		word-break: break-word;
	}
	.card-description {
		font-family: 'Barlow Regular';
		color: rgba(0, 0, 0, 0.54);
		font-size: 0.9rem;
		padding: 10px 0 10px;
		min-height: 24%;
		border: 0px solid red;
		height: 24%;
	}
	.card-chip {
		border: 2px solid #9fb9eb;
		border-radius: 3px;
		padding: 4px 10px;
		font-family: 'Barlow SemiBold';
		color: #08255b;
		width: 48%;
		text-align: center;
		font-size: 0.7rem;
	}
	.card-cta {
		text-transform: uppercase;
		background: #ea5252;
		color: #fff;
		padding: 9px 20px;
		border-radius: 25px;
		width: 50%;
		font-family: 'Poppins Bold';
		text-align: center;
		margin: 20px 0 10px;
		font-size: 0.7rem;
		letter-spacing: 1px;
		border: 2px solid #ea5252;
	}
	.heading {
		font-family: 'Poppins Bold';
		text-transform: uppercase;
		font-size: 1.2rem;
		letter-spacing: 0.1rem;
		color: rgba(0, 0, 0, 0.54);
		margin-top: 2%;
		@include xl {
			font-size: 1.2rem;
		}
		@include lg {
			font-size: 1rem;
		}
		@include md {
			font-size: 1rem;
		}
		@include sm {
			font-size: 1rem;
		}
		@include xs {
			font-size: 1rem;
		}
		@include ippro {
			font-size: 1.1rem;
		}
	}
	.border {
		display: flex;
		padding: 10px;
		border-top: 1px solid #e3e3e3;
		border-left: 1px solid #e3e3e3;
		border-right: 1px solid #e3e3e3;
		justify-content: center;
		flex-direction: column;
		flex-wrap: wrap;
		padding-left: 5px;
		padding-right: 5px;
		@include md {
			padding: 10px;
		}
		@include xs {
			padding: 10px 5px;
		}
	}
}
.functionalityContainer {
	@include flexRow;
	@include flexCenter;
	// padding: 0px 100px;
	// margin-top: 100px;
	// margin-bottom: 50px;
	color: #fff;
	position: relative;
	@include xl {
		margin-top: 90px;
		padding: 0px 50px;
	}
	@include lg {
		margin-top: 60px;
		padding: 0px 20px;
	}
	@include md {
		margin-top: 50px;
		padding: 0px 10px;
	}
	@include sm {
		padding: 0px 5px;
	}
	@include xs {
		width: 320px;
	}
	@keyframes gradient {
		0% {
			background-position: 0% 0%;
		}
		100% {
			background-position: 400% 0%;
		}
	}
	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: url('../../assets/images/banner_grid.png');
		background-size: 3.6rem;
		opacity: 0.25;
	}
	.border {
		display: flex;
		padding: 15px;
		border-top: 1px solid #e3e3e3;
		border-left: 1px solid #e3e3e3;
		border-right: 1px solid #e3e3e3;
		@include md {
			padding: 10px;
		}
		@include xs {
			padding: 10px 5px;
		}
	}
	.borderBottom {
		transition: all 0.3s;
		border-bottom: 6px solid;
		border-image-slice: 1;
		border-image-source: linear-gradient(
			to right,
			#f84c69,
			#f79759,
			#ebe478,
			#a0fb77,
			#64ed97,
			#57b2f3,
			#e475f3
		);
		@include sm {
			border-bottom: 3px solid;
		}
	}
	.cc_icon {
		width: 225px;
		height: 277px;
		@include xl {
			width: 175px;
			height: 190px;
		}
		@include lg {
			width: 132px;
			height: 143px;
			margin-top: 20px;
		}
		@include md {
			width: 100px;
			height: 109px;
		}
		@include sm {
		}
	}
	.content {
		height: 100%;
		width: 100%;
		max-width: 1800px;
		text-align: left;
		margin-left: 20px;
		margin-top: 20px;

		@include lg {
			margin-top: 0;
		}
		@include lg {
			margin-left: 10px;
		}
		.heading {
			font-family: 'Poppins Bold';
			text-transform: uppercase;
			font-size: 1.5rem;
			letter-spacing: 0.1rem;
			@include xl {
				font-size: 1.3rem;
			}
			@include lg {
				font-size: 1rem;
			}
			@include md {
				font-size: 1rem;
			}
			@include sm {
				font-size: 1rem;
			}
			@include xs {
				font-size: 0.8rem;
			}
		}
		.cardContent {
			margin-top: 15px;
			font-size: 1rem;
			font-family: 'Poppins Light';
			@include xl {
				margin-top: 10px;
				font-size: 0.8rem;
			}
			@include lg {
				margin-top: 10px;
				font-size: 0.8rem;
			}
			@include md {
				margin-top: 5px;
				font-size: 0.7rem;
			}
			@include sm {
				margin-top: 5px;
				font-size: 0.6rem;
			}
		}
		.button {
			font-family: 'Poppins Medium';
			margin-top: 15px;
			background-color: #ea5252;
			color: #ffffff;
			border-radius: 50px;
			text-transform: none;
			padding: 6px 25px;
			@include lg {
				font-size: 0.6rem;
			}
			@include md {
				padding: 5px 15px;
			}
		}
	}
	.creative-card-content {
		height: 100%;
		justify-content: space-between;
	}
}
