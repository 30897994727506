@import './../global';

$screen-xs-max: 320px;
$screen-sm-max: 480px;
// Media Query Mixins
@mixin xs {
	@media (max-width: #{$screen-xs-max}) {
		@content;
	}
}
@mixin sm {
	@media (max-width: #{$screen-sm-max}) {
		@content;
	}
}
.card {
	@include flexRow;
	@include flexCenter;
	padding: 0px 100px;
	margin-top: 30px;
	margin-bottom: 8px;
	color: #fff;
	position: relative;
	@include xl {
		margin-top: 90px;
		padding: 0px 20px;
	}
	@include lg {
		margin-top: 60px;
		padding: 0px 20px;
	}
	@include md {
		margin-top: 50px;
		padding: 0px 10px;
	}
	@include sm {
		padding: 0px 5px;
		margin-top: 10px;
	}
	@include ipad {
		padding: 0px 5px;
		margin-top: 15px;
		margin-bottom: 0px;
	}
	@include ippro {
		padding: 0px 5px;
		margin-top: 15px;
		margin-bottom: 0px;
	}
	@include xs {
		width: 320px;
		margin-top: 10px;
	}
	@keyframes gradient {
		0% {
			background-position: 0% 0%;
		}
		100% {
			background-position: 400% 0%;
		}
	}
	.MuiPaper-elevation1 {
		&:hover {
			box-shadow: 0 4px 10px 3px rgba(0, 0, 0, 0.2) !important;
			transition: all 0.3s;
		}
	}
	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: url('../../assets/images/banner_grid.png');
		background-size: 3.6rem;
		opacity: 0.25;
	}
	// &:hover{
	// 	.MuiPaper-elevation1{
	// 		&:hover{
	// 		box-shadow: 0 4px 10px 3px rgba(0, 0, 0, 0.2) !important;
	// 		transition: all 0.3s;
	// 		}
	// 	}
	// }
	.border {
		display: flex;
		// padding: 15px;
		border-top: 1px solid #e3e3e3;
		border-left: 1px solid #e3e3e3;
		border-right: 1px solid #e3e3e3;
		padding-top: 30px;
		padding-bottom: 0px;
		padding-right: 15px;

		@include md {
			padding: 10px;
		}
		@include xs {
			padding: 7px 5px;
			padding-top: 26px;
			padding-bottom: 0px;
		}
		&:hover {
			cursor: pointer;
			.MuiPaper-elevation1 {
				box-shadow: 0 4px 10px 3px rgba(0, 0, 0, 0.2) !important;
				transition: all 0.3s;
			}
		}
	}
	.borderBottom {
		transition: all 0.3s;
		border-bottom: 6px solid;
		border-image-slice: 1;
		border-image-source: linear-gradient(
			to right,
			#f84c69,
			#f79759,
			#ebe478,
			#a0fb77,
			#64ed97,
			#57b2f3,
			#e475f3
		);
		@include sm {
			border-bottom: 3px solid;
		}
	}
	.cc_icon {
		width: auto;
		height: 167px;
		margin: 0 27px;
		@include xl {
			width: 175px;
			height: 190px;
		}
		@include lg {
			width: 132px;
			height: 143px;
			margin-top: 20px;
		}
		@include md {
			width: 100px;
			height: 109px;
			margin-top: 8px;
		}
		@include sm {
		}
	}
	.content {
		height: 100%;
		width: 100%;
		max-width: 1800px;
		text-align: left;
		margin-left: 20px;
		margin-top: 0px;
		margin-bottom: 8%;

		@include lg {
			margin-top: 0;
		}
		@include lg {
			margin-left: 10px;
		}
		.heading {
			font-family: 'Poppins Bold';
			text-transform: uppercase;
			font-size: 1.5rem;
			letter-spacing: 0.1rem;
			@include xl {
				font-size: 1.3rem;
			}
			@include lg {
				font-size: 1rem;
			}
			@include md {
				font-size: 1rem;
			}
			@include sm {
				font-size: 1rem;
			}
			@include xs {
				font-size: 0.8rem;
			}
		}
		.cardContent {
			margin-top: 15px;
			font-size: 1rem;
			font-family: 'Poppins Light';
			@include xl {
				margin-top: 10px;
				font-size: 0.8rem;
			}
			@include lg {
				margin-top: 10px;
				font-size: 0.8rem;
			}
			@include md {
				margin-top: 5px;
				font-size: 0.7rem;
			}
			@include sm {
				margin-top: 5px;
				font-size: 0.6rem;
			}
		}
		.button {
			font-family: 'Poppins Medium';
			margin-top: 15px;
			background-color: #ea5252;
			color: #ffffff;
			border-radius: 50px;
			text-transform: none;
			padding: 6px 25px;
			@include lg {
				font-size: 0.6rem;
			}
			@include md {
				padding: 5px 15px;
			}
		}
	}
}
