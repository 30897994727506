.previewLinkContainer{
    
       
        /* flex-direction: column; */
        width: 67%;
        height: auto;
        background-size: 100% auto;
        position: absolute;
        margin: 00% 0%;
    
    .creativeLinkSection {
        display: flex;
        /* font-size: 12px; */
        font-size: 0.8rem;
        font-family: 'Poppins Regular';
        color: #636363;
        margin: 0.5rem 0 0 0;
    }
    .linkUrlSection{
        display: flex;
        flex-wrap: nowrap;
        margin: 0 1rem;
    }
    .copyClipboard{
        display: flex;
    margin: 0 1rem;
    }
    .copyClipboard span{
        font-size: 1rem;
    }
    .linkCopied{
        color:#4caf50;
    }
    img:hover{
        cursor: pointer;
    }
}
.linkCopied{
    filter: grayscale(1) opacity(0.7);
}
