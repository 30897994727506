@import '../global';

.creativeDetailSection1 {
	@include flexColumn;
	padding: 5% 0%;
	@include md {
		padding: 7%;
	}
	@include sm {
		padding: 10% 5%;
	}

	.creativeDetails {
		@include flexRow;
		justify-content: space-between;
		@include sm {
			@include flexColumn;
			align-items: center;
		}
	}
}
