@import '../global.scss';
$screen-xs-max: 320px;
$screen-sm-max: 480px;
// Media Query Mixins
@mixin xs {
	@media (max-width: #{$screen-xs-max}) {
		@content;
	}
}
@mixin sm {
	@media (max-width: #{$screen-sm-max}) {
		@content;
	}
}
.awardWinningList {
    padding: 40px 20px;
	
	@include lg{
		padding: 40px 20px;
	}
	@include sm{
		padding: 20px 20px 0px;
	}
	.cardViewBtn {
		padding: 10px;
		width: 100%;
		background: hsla(0, 0%, 100%, 0.2);
		border: 0.001rem solid #636363;
		color: #636363;
		text-transform: uppercase;
		margin: 0 auto;
		text-align: center;
	}
	.right {
		width: 10%;
		/* display: flex; */
		/* flex: 0 0; */
		@include sm {
			width: 37%;
		}
	}
	.liner {
		width: 83%;
		content: '';
		/* flex: 1 1; */
		border-bottom: 0.1rem solid #636363;
		margin: auto;
		@include sm {
			width: 16%;
			content: '';
			/* flex: 1 1; */
			border-bottom: 0.1rem solid #636363;
			margin: auto;
		}
	}
	.left {
		width: 14%;
		font-family: 'Poppins Bold';
		color: #636363;
		@include sm {
			width: 44%;
		}
	}
	.examplesContainer .topHeader {
		display: flex;
		width: 100%;
		padding-top: 4%;
		align-content: center;
		align-items: center;
		justify-content: center;
		flex-wrap: nowrap;
		flex-direction: row;
	}
	.examplesContainer {
		width: 100%;
		height: 100%;
		position: relative;
		top: 0;
		padding: calc(2rem - 5px) calc(7% - 5px);
		display: flex;
		flex-direction: column;
	}
	.examplesContainer .mainContainer {
		display: grid;
		grid-template-rows: auto 1fr;
		padding: 5% 0%;
	}

	.exampleList {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(16%, 1fr));
		grid-gap: 2rem;
		@include sm {
			grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
			grid-gap: 1rem;
			display: none;
		}
	}
	.carouselMobileContainer{
		display: none;
		border: 0px solid red;
		position: relative;
		min-width: 25%;
		width: 25%;
		margin: 0 auto;
		@include sm{
			display: block;
			min-width: 80%;
			width: 80%;
			margin: 0 auto;
			
		}
		@include xs{
			display: block;
			min-width: 80%;
			width: 80%;
			margin: 0 auto;
			
		}
		.creativeCarousel{
			.control-next{
				position: absolute !important;
				right:-44px;

			}
			.control-prev{
				position: absolute !important;
				left:-44px;

			}
			.carousel-slider{
				overflow:visible;
			}
			.control-arrow {
				background:grey;
			}
		}
	}
}

