@import '../global.scss';
.examplesContainer {
	// background: white;
	// width: 100%;
	// height: 100%;
	// position: relative;
	// top: 0;
	// padding: calc(2rem - 5px) calc(11% - 0px);
	// display: flex;
	// flex-direction: column;
	// overflow: hidden;
	width: 78%;
    height: 100%;
    position: relative;
    top: 0;
    /* padding: calc(2rem - 5px) calc(11% - 0px); */
    display: flex
;
    flex-direction: column;
    overflow: hidden;
    margin: 0 auto;
	.right {
		width: 11%;
		/* display: flex; */
		/* flex: 0 0; */

		@include md {
			width: 25%;
		}
		@include sm {
			width: 37%;
		}
		@include ippro {
			width: 18%;
		}
	}
	.liner {
		width: 79%;
		content: '';
		/* flex: 1 1; */
		border-bottom: 0.1rem solid #636363;
		margin: auto;
		margin-left:2%;
		@include sm {
			width: 16%;
			content: '';
			/* flex: 1 1; */
			border-bottom: 0.1rem solid #636363;
			margin: auto;
		}
		@include md {
			width: 85%;
		}
	}
	.left {
		width: 8%;
		font-family: 'Poppins Light';
		font-size: 20px;
		color: #636363;
		@include sm {
			width: 44%;
		}
		@include md {
			width: 22%;
		}
		@include ippro {
			width: 17%;
		}
	}
	.button {
		font-family: 'Poppins Medium';

		background-color: #ea5252;
		color: #ffffff;
		border-radius: 50px;
		text-transform: none;
		padding: 6px 25px;
		@include lg {
			font-size: 0.6rem;
		}
		@include md {
			padding: 5px 15px;
		}
		@include sm {
			font-size: 1rem;
		}
		@include xs {
			font-size: 1rem;
		}
	}
	.MuiButton-contained:hover {
		// box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
		background-color: #ea5252;
	}
}

.cardViewBtn {
	padding: 10px;
	width: 100%;
	background: hsla(0, 0%, 100%, 0.2);
	border: 0.001rem solid #636363;
	color: #636363;
	text-transform: uppercase;
	margin: 0 auto;
	text-align: center;
}

.examplesContainer .topHeader {
	display: flex;
	width: 100%;
	padding-top: 4%;
	align-content: center;
	align-items: center;
	justify-content: center;
	flex-wrap: nowrap;
	flex-direction: row;
	@include sm {
		display: none;
	}
}
.examplesContainer .topHeaderMobile {
	display: none;
	font-family: 'Poppins Bold';
	color: #636363;
	text-align: center;
	font-size: 1rem;
	@include sm {
		display: block;
	}
}
.seeMoreMobileContainer {
	// width: 50%;
	margin: 0 auto;
	font-size: 1rem;
	display: none;
	@include sm {
		display: block;
	}
}

.examplesContainer .mainContainer {
	display: grid;
	grid-template-rows: auto 1fr;
	padding: 5% 0%;
}

.exampleList {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(16%, 1fr));
	grid-gap: 2rem;
	@include sm {
		grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
		grid-gap: 1rem;
		display: none;
	}
}
.carouselMobileContainer {
	display: none;
	border: 0px solid red;
	position: relative;
	min-width: 25%;
	width: 25%;
	margin: 0 auto;
	@include sm {
		display: block;
		min-width: 100%;
		width: 100%;
	}
	.dontExistMsgMobile {
		text-align: center;
		display: none;
		@include sm {
			display: block;
			min-width: 100%;
			width: 100%;
		}
	}
}
.phoneBg {
	width: 100%;
}
.carouselMobileContainer .carousel-slider .control-arrow {
	top: calc(50% - 15px);
	color: #fff;
	font-size: 26px;
	bottom: 0;
	margin-top: 0;
	padding: 5px;
	height: 40px;
	border-radius: 50%;
	width: 40px;
	vertical-align: middle;
	background: grey;
}
.carouselMobileContainer {
	button.control-arrow.control-prev {
		left: -39px !important;
		position: absolute !important;
	}
	.carousel .control-next.control-arrow {
		right: -39px;
	}
	.control-arrow.control-prev {
		left: -39px !important;
		position: absolute !important;
	}

	.carousel.carousel-slider {
		overflow: visible;
	}
	.carousel.carousel-slider .control-arrow {
		top: calc(50% - 15px);
		color: #fff;
		font-size: 26px;
		bottom: 0;
		margin-top: 0;
		padding: 5px;
		height: 40px;
		border-radius: 50%;
		width: 40px;
		vertical-align: middle;
		background: grey;
		opacity: 1;
	}
	.carousel.carousel-slider .control-arrow:hover {
		//   background: rgba(0, 0, 0, 0.2);
		background: grey;
	}
}

.dontExistMsg {
	text-align: center;
	@include sm {
		display: none;
	}
}
