@keyframes preload-show-1 {
    from {
      transform: rotateZ(60deg) rotateY(-90deg) rotateX(0deg);
      border-left-color: #636363;
    }
  }
  @keyframes preload-hide-1 {
    to {
      transform: rotateZ(60deg) rotateY(-90deg) rotateX(0deg);
      border-left-color: #636363;
    }
  }
  @keyframes preload-cycle-1 {
    5% {
      transform: rotateZ(60deg) rotateY(90deg) rotateX(0deg);
      border-left-color: #636363;
    }
    10%, 75% {
      transform: rotateZ(60deg) rotateY(0) rotateX(0deg);
      border-left-color: #e3e3e3;
    }
    80%, 100% {
      transform: rotateZ(60deg) rotateY(90deg) rotateX(0deg);
      border-left-color: #636363;
    }
  }
  @keyframes preload-show-2 {
    from {
      transform: rotateZ(120deg) rotateY(-90deg) rotateX(0deg);
      border-left-color: #636363;
    }
  }
  @keyframes preload-hide-2 {
    to {
      transform: rotateZ(120deg) rotateY(-90deg) rotateX(0deg);
      border-left-color: #636363;
    }
  }
  @keyframes preload-cycle-2 {
    10% {
      transform: rotateZ(120deg) rotateY(90deg) rotateX(0deg);
      border-left-color: #636363;
    }
    15%, 70% {
      transform: rotateZ(120deg) rotateY(0) rotateX(0deg);
      border-left-color: #e3e3e3;
    }
    75%, 100% {
      transform: rotateZ(120deg) rotateY(90deg) rotateX(0deg);
      border-left-color: #636363;
    }
  }
  @keyframes preload-show-3 {
    from {
      transform: rotateZ(180deg) rotateY(-90deg) rotateX(0deg);
      border-left-color: #636363;
    }
  }
  @keyframes preload-hide-3 {
    to {
      transform: rotateZ(180deg) rotateY(-90deg) rotateX(0deg);
      border-left-color: #636363;
    }
  }
  @keyframes preload-cycle-3 {
    15% {
      transform: rotateZ(180deg) rotateY(90deg) rotateX(0deg);
      border-left-color: #636363;
    }
    20%, 65% {
      transform: rotateZ(180deg) rotateY(0) rotateX(0deg);
      border-left-color: #e3e3e3;
    }
    70%, 100% {
      transform: rotateZ(180deg) rotateY(90deg) rotateX(0deg);
      border-left-color: #636363;
    }
  }
  @keyframes preload-show-4 {
    from {
      transform: rotateZ(240deg) rotateY(-90deg) rotateX(0deg);
      border-left-color: #636363;
    }
  }
  @keyframes preload-hide-4 {
    to {
      transform: rotateZ(240deg) rotateY(-90deg) rotateX(0deg);
      border-left-color: #636363;
    }
  }
  @keyframes preload-cycle-4 {
    20% {
      transform: rotateZ(240deg) rotateY(90deg) rotateX(0deg);
      border-left-color: #636363;
    }
    25%, 60% {
      transform: rotateZ(240deg) rotateY(0) rotateX(0deg);
      border-left-color: #e3e3e3;
    }
    65%, 100% {
      transform: rotateZ(240deg) rotateY(90deg) rotateX(0deg);
      border-left-color: #636363;
    }
  }
  @keyframes preload-show-5 {
    from {
      transform: rotateZ(300deg) rotateY(-90deg) rotateX(0deg);
      border-left-color: #636363;
    }
  }
  @keyframes preload-hide-5 {
    to {
      transform: rotateZ(300deg) rotateY(-90deg) rotateX(0deg);
      border-left-color: #636363;
    }
  }
  @keyframes preload-cycle-5 {
    25% {
      transform: rotateZ(300deg) rotateY(90deg) rotateX(0deg);
      border-left-color: #636363;
    }
    30%, 55% {
      transform: rotateZ(300deg) rotateY(0) rotateX(0deg);
      border-left-color: #e3e3e3;
    }
    60%, 100% {
      transform: rotateZ(300deg) rotateY(90deg) rotateX(0deg);
      border-left-color: #636363;
    }
  }
  @keyframes preload-show-6 {
    from {
      transform: rotateZ(360deg) rotateY(-90deg) rotateX(0deg);
      border-left-color: #636363;
    }
  }
  @keyframes preload-hide-6 {
    to {
      transform: rotateZ(360deg) rotateY(-90deg) rotateX(0deg);
      border-left-color: #636363;
    }
  }
  @keyframes preload-cycle-6 {
    30% {
      transform: rotateZ(360deg) rotateY(90deg) rotateX(0deg);
      border-left-color: #636363;
    }
    35%, 50% {
      transform: rotateZ(360deg) rotateY(0) rotateX(0deg);
      border-left-color: #e3e3e3;
    }
    55%, 100% {
      transform: rotateZ(360deg) rotateY(90deg) rotateX(0deg);
      border-left-color: #636363;
    }
  }
  @keyframes preload-flip {
    0% {
      transform: rotateY(0deg) rotateZ(-60deg);
    }
    100% {
      transform: rotateY(360deg) rotateZ(-60deg);
    }
  }
  body {
    background: #ffffff;
  }
  
  .preloader {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 20px;
    display: block;
    width: 3.75em;
    height: 4.25em;
    margin-left: -1.875em;
    margin-top: -2.125em;
    transform-origin: center center;
    transform: rotateY(180deg) rotateZ(-60deg);zoom: 0.4;
  }
  .preloader .slice {
    border-top: 1.125em solid transparent;
    border-right: none;
    border-bottom: 1em solid transparent;
    border-left: 1.875em solid #636363;
    position: absolute;
    top: 0px;
    left: 50%;
    transform-origin: left bottom;
    border-radius: 3px 3px 0 0;
  }
  .preloader .slice:nth-child(1) {
    transform: rotateZ(60deg) rotateY(0deg) rotateX(0);
    animation: 0.15s linear 0.82s preload-hide-1 both 1;
  }
  .preloader .slice:nth-child(2) {
    transform: rotateZ(120deg) rotateY(0deg) rotateX(0);
    animation: 0.15s linear 0.74s preload-hide-2 both 1;
  }
  .preloader .slice:nth-child(3) {
    transform: rotateZ(180deg) rotateY(0deg) rotateX(0);
    animation: 0.15s linear 0.66s preload-hide-3 both 1;
  }
  .preloader .slice:nth-child(4) {
    transform: rotateZ(240deg) rotateY(0deg) rotateX(0);
    animation: 0.15s linear 0.58s preload-hide-4 both 1;
  }
  .preloader .slice:nth-child(5) {
    transform: rotateZ(300deg) rotateY(0deg) rotateX(0);
    animation: 0.15s linear 0.5s preload-hide-5 both 1;
  }
  .preloader .slice:nth-child(6) {
    transform: rotateZ(360deg) rotateY(0deg) rotateX(0);
    animation: 0.15s linear 0.42s preload-hide-6 both 1;
  }
  .preloader.loading {
    animation: 2s preload-flip steps(2) infinite both;
  }
  .preloader.loading .slice:nth-child(1) {
    transform: rotateZ(60deg) rotateY(90deg) rotateX(0);
    animation: 2s preload-cycle-1 linear infinite both;
  }
  .preloader.loading .slice:nth-child(2) {
    transform: rotateZ(120deg) rotateY(90deg) rotateX(0);
    animation: 2s preload-cycle-2 linear infinite both;
  }
  .preloader.loading .slice:nth-child(3) {
    transform: rotateZ(180deg) rotateY(90deg) rotateX(0);
    animation: 2s preload-cycle-3 linear infinite both;
  }
  .preloader.loading .slice:nth-child(4) {
    transform: rotateZ(240deg) rotateY(90deg) rotateX(0);
    animation: 2s preload-cycle-4 linear infinite both;
  }
  .preloader.loading .slice:nth-child(5) {
    transform: rotateZ(300deg) rotateY(90deg) rotateX(0);
    animation: 2s preload-cycle-5 linear infinite both;
  }
  .preloader.loading .slice:nth-child(6) {
    transform: rotateZ(360deg) rotateY(90deg) rotateX(0);
    animation: 2s preload-cycle-6 linear infinite both;
  }