@import '../global';

.creativeListingSection {
	display: grid;
	grid-template-rows: auto 1fr;
	padding: 5% 7%;

	@include md {
		padding: 7%;
	}
	@include sm {
		padding: 10% 5%;
	}
}
