$primary-color: #0a4dcc;
$secondary-color: #fe7c37;
$text-color: #636363;
$screen-xs-max: 499px;
$screen-sm-max: 599px;
$screen-md-max: 768px;
$screen-lg-max: 900px;
$screen-xl-max: 1200px;

// Media Query Mixins
@mixin xs {
	@media (max-width: #{$screen-xs-max}) {
		@content;
	}
}
@mixin sm {
	@media (max-width: #{$screen-sm-max}) {
		@content;
	}
}
@mixin md {
	@media (max-width: #{$screen-md-max}) {
		@content;
	}
}
@mixin lg {
	@media (max-width: #{$screen-lg-max}) {
		@content;
	}
}
@mixin xl {
	@media (max-width: #{$screen-xl-max}) {
		@content;
	}
}
@mixin ippro {
	@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
		@content;
	}
}
/* more than 1370px*/
@mixin desktop {
	@media only screen and (min-width: 1370px) and (max-width: 1800px) {
		@content;
	}
}
/* 1024 resolution*/
@mixin laptop {
	@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
		@content;
	}
}
/* 1366 resolution*/
@mixin laptop1 {
	@media only screen and (min-width: 1030px) and (max-width: 1366px) {
		@content;
	}
}
@mixin ipad {
	@media all and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
		@content;
	}
}
// Placeholder Mixin
@mixin placeholder {
	::-webkit-input-placeholder {
		@content;
	}
	:-moz-placeholder {
		@content;
	}
	::-moz-placeholder {
		@content;
	}
	:-ms-input-placeholder {
		@content;
	}
}
// Global Class Mixins
@mixin flexRow {
	display: flex;
	flex-direction: row;
}
@mixin flexColumn {
	display: flex;
	flex-direction: column;
}
@mixin flexCenter {
	justify-content: center;
	align-items: center;
}
@mixin flexSpaceBetween {
	justify-content: space-between;
	align-items: center;
}
@mixin flexStart {
	justify-content: flex-start;
	align-items: center;
}
@mixin flexEnd {
	justify-content: flex-end;
	align-items: center;
}
@mixin flexVAlign {
	align-items: center;
}
@mixin hidden {
	display: none !important;
}
.hidden {
	display: none !important;
}
.invisible {
	visibility: hidden;
}
